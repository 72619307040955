import { inject, Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { Query, Tenant } from '../generated';
import { RcApiService } from './rc-api.service';

@Injectable()
export class TenantService {
  private readonly rcApiService = inject(RcApiService);

  getProfile(): Observable<Tenant | null> {
    return this.rcApiService.apollo
      .query<Query>({
        query: gql`
          query GetTenantProfile {
            getTenantProfile {
              id
              name
              email
              propertyUnit {
                id
                name
                active
                createdAt
                updatedAt
                property {
                  id
                  address
                }
              }
            }
          }
        `,
      })
      .pipe(
        map((result) => {
          return result.data.getTenantProfile!;
        }),
      );
  }
}
